// import React, { useContext } from "react";
// import { Link } from "gatsby";
// import PageWrapper from "../components/PageWrapper";
// import { Select } from "../components/Core";
// import GlobalContext from "../context/GlobalContext";

// import imgP1 from "../assets/image/table-one-profile-image-1.png";
// import imgP2 from "../assets/image/table-one-profile-image-2.png";
// import imgP3 from "../assets/image/table-one-profile-image-3.png";
// import imgP4 from "../assets/image/table-one-profile-image-4.png";
// import imgP5 from "../assets/image/table-one-profile-image-5.png";

// const defaultJobs = [
//   { value: "pd", label: "Product Designer" },
//   { value: "gd", label: "Graphics Designer" },
//   { value: "fd", label: "Frontend Developer" },
//   { value: "bd", label: "Backend Developer" },
//   { value: "cw", label: "Content Writer" },
// ];

// const DashboardApplicants = () => {
//   const gContext = useContext(GlobalContext);

//   return (
//     <>
//       <PageWrapper
//         headerConfig={{
//           button: "profile",
//           isFluid: true,
//           bgClass: "bg-default",
//           reveal: false,
//         }}
//       >
//         <div className="dashboard-main-container mt-25 mt-lg-31">
//           <div className="container">
//             <div className="mb-14">
//               <div className="row mb-11 align-items-center">
//                 <div className="col-lg-6 mb-lg-0 mb-4">
//                   <h3 className="font-size-6 mb-0">Applicants List (12)</h3>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
//                     <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
//                     <div className="h-px-48">
//                       <Select
//                         options={defaultJobs}
//                         className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
//                         border={false}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
//                 <div className="table-responsive">
//                   <table className="table table-striped">
//                     <thead>
//                       <tr>
//                         <th
//                           scope="col"
//                           className="pl-0  border-0 font-size-4 font-weight-normal"
//                         >
//                           Name
//                         </th>
//                         <th
//                           scope="col"
//                           className="border-0 font-size-4 font-weight-normal"
//                         >
//                           Applied as
//                         </th>
//                         <th
//                           scope="col"
//                           className="border-0 font-size-4 font-weight-normal"
//                         >
//                           Applied on
//                         </th>
//                         <th
//                           scope="col"
//                           className="border-0 font-size-4 font-weight-normal"
//                         ></th>
//                         <th
//                           scope="col"
//                           className="border-0 font-size-4 font-weight-normal"
//                         ></th>
//                         <th
//                           scope="col"
//                           className="border-0 font-size-4 font-weight-normal"
//                         ></th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr className="border border-color-2">
//                         <th scope="row" className="pl-6 border-0 py-7 pr-0">
//                           <Link
//                             to="/candidate-profile"
//                             className="media min-width-px-235 align-items-center"
//                           >
//                             <div className="circle-36 mr-6">
//                               <img src={imgP1} alt="" className="w-100" />
//                             </div>
//                             <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
//                               Nicolas Bradley
//                             </h4>
//                           </Link>
//                         </th>
//                         <td className="table-y-middle py-7 min-width-px-235 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             Senior Project Manager
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             12 July, 2020
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <div className="">
//                             <a
//                               href="/#"
//                               className="font-size-3 font-weight-bold text-black-2 text-uppercase"
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 gContext.toggleApplicationModal();
//                               }}
//                             >
//                               View Application
//                             </a>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-110 pr-0">
//                           <div className="">
//                             <Link
//                               to="/contact"
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Contact
//                             </Link>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-100 pr-0">
//                           <div className="">
//                             <Link
//                               to="/#"
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Reject
//                             </Link>
//                           </div>
//                         </td>
//                       </tr>
//                       <tr className="border border-color-2">
//                         <th scope="row" className="pl-6 border-0 py-7 pr-0">
//                           <Link
//                             to="/candidate-profile"
//                             className="media min-width-px-235 align-items-center"
//                           >
//                             <div className="circle-36 mr-6">
//                               <img src={imgP2} alt="" className="w-100" />
//                             </div>
//                             <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
//                               Elizabeth Gomez
//                             </h4>
//                           </Link>
//                         </th>
//                         <td className="table-y-middle py-7 min-width-px-235 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             Senior Project Manager
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             14 July, 2020
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <div className="">
//                             <a
//                               href="/#"
//                               className="font-size-3 font-weight-bold text-black-2 text-uppercase"
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 gContext.toggleApplicationModal();
//                               }}
//                             >
//                               View Application
//                             </a>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-110 pr-0">
//                           <div className="">
//                             <Link
//                               to="/contact"
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Contact
//                             </Link>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-100 pr-0">
//                           <div className="">
//                             <Link
//                               to="/#"
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Reject
//                             </Link>
//                           </div>
//                         </td>
//                       </tr>
//                       <tr className="border border-color-2">
//                         <th scope="row" className="pl-6 border-0 py-7 pr-0">
//                           <Link
//                             to="/candidate-profile"
//                             className="media min-width-px-235 align-items-center"
//                           >
//                             <div className="circle-36 mr-6">
//                               <img src={imgP3} alt="" className="w-100" />
//                             </div>
//                             <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
//                               Joe Wade
//                             </h4>
//                           </Link>
//                         </th>
//                         <td className="table-y-middle py-7 min-width-px-235 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             Head of Marketing
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             14 July, 2020
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <div className="">
//                             <a
//                               href="/#"
//                               className="font-size-3 font-weight-bold text-black-2 text-uppercase"
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 gContext.toggleApplicationModal();
//                               }}
//                             >
//                               View Application
//                             </a>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-110 pr-0">
//                           <div className="">
//                             <Link
//                               to="/contact"
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Contact
//                             </Link>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-100 pr-0">
//                           <div className="">
//                             <Link
//                               to="/#"
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Reject
//                             </Link>
//                           </div>
//                         </td>
//                       </tr>
//                       <tr className="border border-color-2">
//                         <th scope="row" className="pl-6 border-0 py-7 pr-0">
//                           <Link
//                             to="/candidate-profile"
//                             className="media min-width-px-235 align-items-center"
//                           >
//                             <div className="circle-36 mr-6">
//                               <img src={imgP4} alt="" className="w-100" />
//                             </div>
//                             <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
//                               Roger Hawkins
//                             </h4>
//                           </Link>
//                         </th>
//                         <td className="table-y-middle py-7 min-width-px-235 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             UI Designer
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             16 July, 2020
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <div className="">
//                             <a
//                               href="/#"
//                               className="font-size-3 font-weight-bold text-black-2 text-uppercase"
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 gContext.toggleApplicationModal();
//                               }}
//                             >
//                               View Application
//                             </a>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-110 pr-0">
//                           <div className="">
//                             <Link
//                               to="/contact"
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Contact
//                             </Link>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-100 pr-0">
//                           <div className="">
//                             <Link
//                               to="/#"
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Reject
//                             </Link>
//                           </div>
//                         </td>
//                       </tr>
//                       <tr className="border border-color-2">
//                         <th scope="row" className="pl-6 border-0 py-7 pr-0">
//                           <Link
//                             to="/candidate-profile"
//                             className="media min-width-px-235 align-items-center"
//                           >
//                             <div className="circle-36 mr-6">
//                               <img src={imgP5} alt="" className="w-100" />
//                             </div>
//                             <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
//                               Marie Green
//                             </h4>
//                           </Link>
//                         </th>
//                         <td className="table-y-middle py-7 min-width-px-235 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             Senior Project Manager
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                             21 July, 2020
//                           </h3>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-170 pr-0">
//                           <div className="">
//                             <a
//                               href="/#"
//                               className="font-size-3 font-weight-bold text-black-2 text-uppercase"
//                               onClick={(e) => {
//                                 e.preventDefault();
//                                 gContext.toggleApplicationModal();
//                               }}
//                             >
//                               View Application
//                             </a>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-110 pr-0">
//                           <div className="">
//                             <Link
//                               to="/contact"
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Contact
//                             </Link>
//                           </div>
//                         </td>
//                         <td className="table-y-middle py-7 min-width-px-100 pr-0">
//                           <div className="">
//                             <Link
//                               to="/#"
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Reject
//                             </Link>
//                           </div>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="pt-2">
//                   <nav aria-label="Page navigation example">
//                     <ul className="pagination pagination-hover-primary rounded-0 ml-n2">
//                       <li className="page-item rounded-0 flex-all-center">
//                         <a
//                           href="/#"
//                           className="page-link rounded-0 border-0 px-3active"
//                           aria-label="Previous"
//                         >
//                           <i className="fas fa-chevron-left"></i>
//                         </a>
//                       </li>
//                       <li className="page-item">
//                         <a
//                           href="/#"
//                           className="page-link border-0 font-size-4 font-weight-semibold px-3"
//                         >
//                           1
//                         </a>
//                       </li>
//                       <li className="page-item">
//                         <a
//                           href="/#"
//                           className="page-link border-0 font-size-4 font-weight-semibold px-3"
//                         >
//                           2
//                         </a>
//                       </li>
//                       <li className="page-item">
//                         <a
//                           href="/#"
//                           className="page-link border-0 font-size-4 font-weight-semibold px-3"
//                         >
//                           3
//                         </a>
//                       </li>
//                       <li className="page-item disabled">
//                         <a
//                           href="/#"
//                           className="page-link border-0 font-size-4 font-weight-semibold px-3"
//                         >
//                           ...
//                         </a>
//                       </li>
//                       <li className="page-item ">
//                         <a
//                           href="/#"
//                           className="page-link border-0 font-size-4 font-weight-semibold px-3"
//                         >
//                           7
//                         </a>
//                       </li>
//                       <li className="page-item rounded-0 flex-all-center">
//                         <a
//                           href="/#"
//                           className="page-link rounded-0 border-0 px-3"
//                           aria-label="Next"
//                         >
//                           <i className="fas fa-chevron-right"></i>
//                         </a>
//                       </li>
//                     </ul>
//                   </nav>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };
// export default DashboardApplicants;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import network from '../../constants/Network';

const DashboardApplicants = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const jobId = params.get("id");
  const jobTitle = params.get("title");
  const gContext = useContext(GlobalContext);
  const [applicants, setApplicants] = useState([]);
  const isMountedRef = useRef(false);
  const { user } = useContext(GlobalContext);
  const [filter, setFilter] = useState('');
  const [allApplicantRoles, setAllApplicantRoles] = useState([]);
  const [totalApplicantCount, setTotalApplicantCount] = useState(0);
  const [sortByDate, setSortByDate] = useState('descending');

  const imageBaseUrl = `${network.serverip}/images/`;

  const [userId, setUserId] = useState('');

  const [paginationData, setPaginationData] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 10,
  });

  const handleSortByDate = async () => {
    const newSortByDate = sortByDate === 'ascending' ? 'descending' : 'ascending';
    setSortByDate(newSortByDate)
  };

  const handleFilterApplicant = async (filter) => {
    fetchApplicants(userId, jobId, filter);
  };

  const applicantFilterOptions = [
    { value: '', label: 'Clear Filter' },
    ...Array.from(
      new Map(
        allApplicantRoles
          .filter(item => item.id && item.name)
          .map(item => [item.id, {
            value: item.id,
            label: item.name
          }])
      ).values()
    )
  ];

  const fetchApplicants = async (userId, jobId, filter, page = 1) => {
    try {

      const response = await axios.post(
        `${network.serverip}/api/applicants-list-forweb`,
        {
          userId: userId,
          jobId: jobId,
          sortByDate: sortByDate,
          applicantRoleId: filter,
          page: page
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success && isMountedRef.current) {
        const applicantsData = response.data.applicantsData || [];
        const applicantRolesData = response.data.allApplicantRoles || [];
        setApplicants(applicantsData);
        setAllApplicantRoles(applicantRolesData);
        setPaginationData(response.data.pagination);
        setTotalApplicantCount(response.data.totalApplicantCount);
        console.log('Fetched applicants:', applicantsData);
        console.log('Fetched roles:', applicantRolesData);
        console.log('Fetched applicants count:', response.data.totalApplicantCount);
      } else if (!response.data.success) {
        console.error('Failed to fetch applicants');
      }

    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {

    if (userId && jobId) {
      const fetchData = async () => {
        await fetchApplicants(userId, jobId);
      };

      fetchData();
    }

  }, [userId, jobId, sortByDate]);

  const styles = dashboardApplicantsStyling();

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-33 mt-md-30">
          <div className="container">
            <div className="mb-14">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">{`Applicants (${totalApplicantCount})`}</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <p className="font-size-4 mb-0 mr-6 py-2">Filter by Applicant Role:</p>
                    <div className="h-px-48">
                      <Select
                        options={applicantFilterOptions}
                        className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center w-100"
                        border={false}
                        onChange={(selectedOption) => {
                          handleFilterApplicant(selectedOption.value);
                          setFilter(selectedOption.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-start">
                <p className="font-size-4 text-black-2 mb-0">{`Job title: ${jobTitle}`}</p>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          Cover letter
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          <button
                            onClick={() => {
                              handleSortByDate();
                            }}
                            className="d-flex align-items-center justify-content-center bg-transparent border-0 p-0"
                            style={styles.tableHeadingButton}
                          >
                            <span>
                              Applied on
                              <span className="ml-1">
                                {sortByDate === "ascending" ? "▲" : "▼"}
                              </span>
                            </span>
                          </button>
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                        >
                          Actions
                        </th>
                        <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-bold"
                          aria-hidden="true"
                        ></th>
                        {/* <th
                          scope="col"
                          className="border-0 font-size-4 font-weight-normal"
                          aria-hidden="true"
                        ></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {applicants.length === 0 ? (
                        <tr>
                          <td colSpan="100%" className="text-center py-4">
                            <p className="mb-0">No applicants found</p>
                          </td>

                        </tr>
                      ) : (
                        <>
                          {applicants.map((applicant, index) => (
                            <tr className="border border-color-2" key={applicant.id}>
                              <th scope="row" className="pl-6 border-0 py-7 pr-0 table-y-middle">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {`${((paginationData.current_page - 1) * 10) + index + 1}`}
                                </h3>
                              </th>
                              <td className="table-y-middle py-7 min-width-px-70">
                                <button
                                  onClick={() => gContext.toggleImageViewModal(applicant?.image)}
                                  style={styles.imageViewButton}
                                >
                                  <img
                                    src={applicant && applicant.image ? imageBaseUrl + applicant.image : ""}
                                    alt=""
                                    style={styles.imageContainer}
                                  />
                                </button>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-135 pr-0">
                                <Link
                                  to={`/profile-details-view/?id=${applicant.user_id}`}
                                  className="font-size-3 mb-0 font-weight-semibold text-black-2"
                                >
                                  <span>
                                    {`${applicant.name}`}
                                  </span>
                                </Link>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-96 pr-0">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {`${applicant?.role}`}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-170 pr-3">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                  <h3 className="mb-0">
                                    {applicant?.cover_letter ? (
                                      <p className="mb-0 font-size-3 font-weight-normal text-black-2 ">
                                        {applicant.cover_letter.length > 50
                                          ? applicant.cover_letter.substring(0, 47) + '...'
                                          : applicant.cover_letter}
                                      </p>
                                    ) : null}
                                  </h3>
                                </div>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-135 pr-0">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {applicant?.created_at ?
                                    new Date(applicant.created_at).toLocaleDateString('default', {
                                      day: '2-digit',
                                      month: 'short',
                                      year: 'numeric'
                                    }).replace(/ /g, '-')
                                    : ''}
                                </h3>
                              </td>
                              {/* <td className="table-y-middle py-7 min-width-px-170 pr-0">
                                  <button
                                    className="btn btn-outline-success rounded-3"
                                    style={styles.viewButtonStyle}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      gContext.toggleApplicationModal();
                                    }}
                                  >
                                     <i className="fas fa-eye"></i>
                                     <span className="pl-3">View Application</span>
                                  </button>
                              </td> */}
                              <td className="table-y-middle py-7 pr-0">
                                <div className="">
                                  <button
                                    className="btn btn-outline-success rounded-3"
                                    style={styles.viewButtonStyle}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      gContext.toggleApplicationModal(applicant?.id);
                                    }}
                                  >
                                    <i className="fas fa-eye"></i>
                                    <span className="pl-3">View Application</span>
                                  </button>
                                </div>
                              </td>
                              <td className="table-y-middle py-7 pr-5">
                                <div className="">
                                  <Link
                                    to="/contact"
                                    className="btn btn-success rounded-3"
                                    style={styles.contactButtonStyle}
                                  >
                                    Contact
                                  </Link>
                                </div>
                              </td>
                              {/* <td className="table-y-middle py-7 pr-5">
                                <div className="">
                                  <Link
                                    to="/#"
                                    className="btn btn-danger rounded-3"
                                    style={styles.rejectButtonStyle}
                                  >
                                    Reject
                                  </Link>
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination w-100 d-flex justify-content-center align-items-center mt-5">
                  {paginationData && paginationData.current_page > 1 ? (
                    <button
                      onClick={() => fetchApplicants(userId, jobId, filter, paginationData.current_page - 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.previousButtonStyle}
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.previousButtonStyle}
                      disabled
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  )}

                  {paginationData && (
                    <span className="ml-5 mr-5 text-success">
                      Page {paginationData.current_page} of {paginationData.last_page}
                    </span>
                  )}

                  {paginationData && paginationData.current_page < paginationData.last_page ? (
                    <button
                      onClick={() => fetchApplicants(userId, jobId, filter, paginationData.current_page + 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.nextButtonStyle}
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.nextButtonStyle}
                      disabled
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default DashboardApplicants;

function dashboardApplicantsStyling() {
  const styles = {

    imageContainer: {
      width: '3rem',
      height: '3rem',
      objectFit: 'cover',
      borderRadius: '5px',
    },
    applicantsButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    viewButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '10rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    contactButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    rejectButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    previousButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    nextButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },

    tableHeadingButton: {
      cursor: "pointer",
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
      textAlign: "left",
      background: "none",
      outline: 'none',
    }

  };

  return styles;
}